<template>
  <div :class="['permission_sidebar',state.sidebaris?'boxmax':'boxmin']">
    <div class="titlebox dp-f mb-20">
      <img class="title_icon cu-p mr-10" :style="{transform:state.sidebaris?'':'rotateY(180deg)'}" src="@/assets/img/general/Expand.png" @click="sidebarchange">
      <div class="c-default cu-p" @click="sidebarchange">{{state.sidebaris?'收起':'展开'}}</div>
    </div>
    <div class="listbox">
      <div class="listitem listitem_title dp-f mb-20">
        <el-tooltip
          :content='title'
          placement="top"
        >
          <div class="title">{{title}}</div>
        </el-tooltip>
        <template v-if="isupdate===true">
          <div class="icon" v-show="state.sidebaris">
            <img class="cu-p" src="@/assets/img/general/add.png" @click="sidebaritemAdd()" v-permission="promisedata.add">
          </div>
        </template>
      </div>
      <template v-for="(el,ind) in sidebardata" :key="ind">
        <div class="listitem listitem_item dp-f mb-16" >
          <el-tooltip
            :content='el.title'
            placement="top"
          >
            <div :class="['title','ml-30','cu-p',showInd===ind?'itemAc':'']" @click="sidebaritemClick(el,ind)">{{el.title}}</div>
          </el-tooltip>
          <template v-if="isupdate===true">
            <div class="icon"  v-show="state.sidebaris">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <img class="cu-p" src="@/assets/img/general/extend.png">
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <div v-permission="promisedata.edit">
                      <el-dropdown-item @click="sidebaritemEdit(el)" >编辑</el-dropdown-item>
                    </div>
                    <div v-permission="promisedata.del">
                      <el-dropdown-item @click="sidebaritemDelete(el)">删除</el-dropdown-item>
                    </div>
                    <div v-permission="promisedata.check">
                      <el-dropdown-item @click="sidebaritemCheck(el)">查看</el-dropdown-item>
                    </div>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </div>
      </template>
      <div class="nodata" v-if="sidebardata.length===0">
         暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent,reactive,ref } from 'vue'

export default defineComponent({
    props: {
      title: {
        type: String,
        default: '标题'
      },
      sidebardata:{
        type: Array,
        default: [
          // {title:'类型1'}
        ]
      },
      // 是否可修改
      isupdate:{
        type: Boolean,
        default: true
      },
      // 传入的权限
      promisedata:{
        type: Object,
        default: {
          add:'system_dictionary:typeAdd',
          edit:'system_dictionary:typeEdit',
          del:'system_dictionary:typeDel',
          check:'system_dictionary:typeCheck'
        }
      }
    },
    setup(props, { emit }) {
      const showInd = ref(0);//当前展示的下标
      let state = reactive({
          sidebaris: true
      });
      let sidebarchange=(()=>{
        state.sidebaris=!state.sidebaris
      })
      let sidebaritemClick=((item,ind)=>{
        showInd.value=ind
        emit('sidebaritemClick',item)
      })
      let sidebaritemEdit=((item)=>{
        emit('sidebaritemEdit',item)
      })
      let sidebaritemDelete=((item)=>{
        emit('sidebaritemDelete',item)
      })
      let sidebaritemCheck=((item)=>{
        emit('sidebaritemCheck',item)
      })
      let sidebaritemAdd=(()=>{
        emit('sidebaritemAdd')
      })
      return {state,showInd,sidebarchange,sidebaritemClick,sidebaritemEdit,sidebaritemDelete,sidebaritemAdd,sidebaritemCheck}
    },
})
</script>
<style lang="scss" scoped >
.boxmax{
  width: 300px;
}
.boxmin{
  width: 100px;
  max-width: 100px;
  overflow: hidden;
}
.permission_sidebar{
  box-sizing: border-box;
  padding-right: 20px;
  transition: 1s all;
  flex-shrink:0;//弹性布局 有剩余空间也不缩小
  .titlebox{
    white-space: nowrap;
    overflow: hidden;/* 单行超出 */
    text-overflow: ellipsis;
    .title_icon{
      transition: 1s all;
      width: 26px;
      height: 20px;
    }
  }
  .listbox{
    .listitem{
      width: 100%;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;/* 单行超出 */
    }
    .listitem_title{
      height: 28px;
      .title{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #292728;
        white-space: nowrap;
        overflow: hidden;/* 单行超出 */
        text-overflow: ellipsis;
      }
    }
    .listitem_item{
      .title{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #292728;
        white-space: nowrap;
        overflow: hidden;/* 单行超出 */
        text-overflow: ellipsis;
      }
    }
    .itemAc{
      color: #0BB189!important;
    }
  }
}
</style>